<template>
  <div>
    <TaskFilterVue
      :start="startDate"
      :end="endDate"
      :active="activeBtn"
      :filter="filter"
      @updateDate="updateDate"
      @applyFilter="applyFilters($event)"
    />
    <div class="task-sum-report">

      <div class="report-filters">
        <p>Group By : </p>
        <div class="filter">
          <div class="w-lg-100">
            <b-form-select
              v-model="firstGroupBy"
              :options="firstGroupByList"
            />
          </div>

          <div
            v-if="firstGroupBy && firstGroupBy !== 'none'"
            class=""
          >
            <b-form-select
              v-model="secondGroupBy"
              :options="secondGroupByList"
            />
          </div>
          <div
            v-if="
              secondGroupBy &&
                secondGroupBy !== 'none' &&
                secondGroupBy !== 'user_task_title' &&
                secondGroupBy !== thirdGroupBy
            "
            class=""
          >
            <b-form-select
              v-model="thirdGroupBy"
              :options="thirdGroupByList"
            />
          </div>
        </div>
      </div>
      <div
        v-if="loader"
      >
        <timesheet-loader :row-num="3" />
      </div>

      <div
        v-if="objectKeys && objectKeys.length"
        class="task-sum-content"
      >
        <div class="task-sum-header">
          <p>
            Title
          </p>
          <p>Duration</p>
        </div>
        <div class="collapse-task-data">

          <b-collapse
            v-for="(task, index) in objectKeys"
            id="collapse-all"
            :key="index"
            class="task-sum-detail"
            visible
          >
            <div
              v-b-toggle="`${index}`"
              :class="task ? 'main-collapse' : 'main-collapse cursor-default'"
              :style="secondGroupBy == 'none'? 'backgroundColor:white;color:#6e6b7b':null"
              @click="redirectToTimeSheet(task,null,null)"
            >
              <div class="person-detail">
                <b-badge variant="light-primary" class="badge-dot">
                  {{ objectKeysLength(reports[task]) }}
                </b-badge>
                <div class="dot" />

                <p
                  class="person-name"
                  :style="{color: projectColor(task)}"
                >
                  {{ task ? showText(task) : without(firstGroupBy) }}
                </p>
              </div>
              <p
                class="total-duration-hr"
                :style="secondGroupBy == 'none'? 'color:#6e6b7b':null"
              >
                {{
                  firstGroupBy !== "none" && secondGroupBy == "none"
                    ? getTotalTime(reports[task])
                    : userProjectDescriptionTotalTime(reports[task])
                }}
              </p>
            </div>
            <b-collapse :id="`${index}`">
              <div class="inner-content">
                <div
                  v-if="firstGroupBy && secondGroupBy !== 'none'"
                  class="content-list"
                >

                  <ul v-if="secondGroupBy == 'user_task_title'">
                    <li
                      v-for="(task2, index2) in Object.keys(reports[task])"
                      :key="index2"
                      :class="task && task2 ? 'main-list px-0':'main-list px-0 cursor-default'"
                    >
                      <div class="inner-detail-date-wise">
                        <p class="month">
                          {{ task2 ? showText(task2) : without(secondGroupBy) }}
                        </p>
                        <p class="total-duration-hr">
                          {{ getProjectTotalTime(reports[task][task2]) }}
                        </p>
                      </div>
                    </li>
                  </ul>
                  <ul v-else>
                    <li
                      v-for="(task2, index2) in Object.keys(reports[task])"
                      :key="index2"
                      class="main-list px-0"
                    >
                      <div class="inner-user-project-detail">
                        <div
                          v-b-toggle="`collapse-1-inner-${index}-${index2}`"
                          class="inner-collapse"
                          :class="task && task2 ? 'inner-collapse':'inner-collapse cursor-default'"
                          @click="redirectToTimeSheet(task,task2,null)"
                        >
                          <div class="person-detail">
                            <b-badge variant="light-primary">
                              {{ objectKeysLength(reports[task][task2]) }}
                            </b-badge>
                            <p
                              v-if="secondGroupBy == 'project_name'"
                              class="person-name"
                              :style="{
                                color: thirdGroupBy !== 'none' && thirdGroupBy !== 'user_task_title'
                                  ? '#' + (reports[task] && reports[task][task2] && reports[task][task2][0] && reports[task][task2][0][0]
                                    ? reports[task][task2][0][0].color
                                    : '')
                                  : '#' + (reports[task] && reports[task][task2] && reports[task][task2][0]
                                    ? reports[task][task2][0].color
                                    : '')
                              }"
                            >
                              {{ task2 }}

                            </p>
                            <p
                              v-else
                              class="person-name"
                            >
                              {{ task2 ? showText(task2) : without(secondGroupBy) }}
                            </p>

                          </div>
                          <p class="total-duration-hr">
                            {{ getProjectTotalTime(reports[task][task2]) }}
                          </p>
                        </div>
                        <b-collapse
                          v-if="thirdGroupBy && thirdGroupBy !== 'none'"
                          :id="`collapse-1-inner-${index}-${index2}`"
                        >
                          <div
                            v-for="(task3, index3) in reports[task][task2]"
                            :key="index3"
                            class="inner-detail-date-wise"
                            @click="redirectToTimeSheet(task,task2,index3)"
                          >
                            <p
                              v-if="task3 && task3.length && task3[0].color"
                              :style="{
                                color: thirdGroupBy === 'project_name' ? '#'+task3[0].color:null
                              }"
                              class="month"
                            >
                              {{ index3 ? showText(index3) : without(thirdGroupBy) }}
                            </p>
                            <p class="total-duration-hr">
                              {{
                                task3 &&
                                  task3.length &&
                                  task3[0].total_time &&
                                  task3[0].total_time ? secondsToTime(task3[0].total_time * 60) : '00:00'
                              }}
                            </p>
                          </div>
                        </b-collapse>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </b-collapse>
          </b-collapse>
        </div>
      </div>
      <div
        v-else
        class="w-100"
      >
        <img
          v-if="objectKeys && !objectKeys.length"
          src="@/assets/images/nodatafound/nodata.svg"
          alt="no-data"
          class="no-data-img"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { BCollapse, VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import TimesheetLoader from '@/components/loaders/timesheet-loader.vue'
import TaskFilterVue from './TaskFilter.vue'

export default {
  name: 'TaskSummaryReport',
  components: {
    BCollapse,
    TaskFilterVue,
    TimesheetLoader,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      firstGroupBy: 'full_name',
      secondGroupBy: 'none',
      thirdGroupBy: 'none',
      optionList: [
        {
          text: 'None',
          value: 'none',
          label: 'none',
        },
        {
          text: 'Project',
          value: 'project_name',
          label: 'project',
        },
        {
          text: 'Client',
          value: 'client_name',
          label: 'client',
        },
        {
          text: 'User',
          value: 'full_name',
          label: 'user',
        },
        {
          text: 'Group',
          value: 'group_name',
          label: 'group',
        },
        {
          text: 'Tag',
          value: 'tags',
          label: 'tag',
        },
        {
          text: 'Description',
          value: 'user_task_title',
          label: 'description',
        },
      ],
      firstGroupByList: [],
      secondGroupByList: [],
      thirdGroupByList: [],
      loader: false,
      startDate: moment().startOf('week').add(1, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      totalTime: 0,
      reports: {},
      objectKeys: [],
      projectIds: [],
      tagList: [],
      isBillable: null,
      groupId: null,
      client_id: [],
      filter: {
        groupId: null,
        projectIds: this.$route.query.summaryProjects ? typeof this.$route.query.summaryProjects === 'string' ? [this.$route.query.summaryProjects] : this.$route.query.summaryProjects : [],
        tagList: [],
        isBillable: null,
        client_id: [],
      },
      activeBtn: (this.$route.query && this.$route.query.active) || null,
      memberList: [],
    }
  },
  watch: {
    firstGroupBy() {
      this.watcherFunction()
    },
    secondGroupBy() {
      this.watcherFunction()
    },
    thirdGroupBy() {
      this.watcherFunction()
    },
  },

  mounted() {
    if (this.$route.query.summaryStartDate) {
      this.setFilterValue()
    } else {
      this.setOptionValue()
      this.summaryReport()
    }
    if (!this.$store.state.app.usersList.length) {
      this.userList()
    }
  },

  methods: {
    watcherFunction() {
      if (!Object.values(this.$route.query).length) {
        this.setOptionValue()
      }
    },
    showText(string) {
      return string.replaceAll(',', ', ')
    },
    filterValueSetInQuery(id, arr, type) {
      let value = null
      const tags = id ? id.split(',') : []
      if (id) {
        value = type === 'tag' ? [...arr, ...tags] : [...arr, ...[id]]
      } else {
        value = [...arr]
      }
      value = value.filter((item, index, self) => self.indexOf(item) === index)
      return value
    },
    setFilterValue() {
      if (this.$route.query) {
        this.startDate = this.$route.query.summaryStartDate ? this.$route.query.summaryStartDate : this.startDate
        this.endDate = this.$route.query.summaryEndDate ? this.$route.query.summaryEndDate : this.endDate
        this.groupId = this.$route.query.summaryGroup ? this.$route.query.summaryGroup : this.groupId
        this.projectIds = this.$route.query.summaryProjects ? typeof this.$route.query.summaryProjects === 'string' ? [this.$route.query.summaryProjects] : this.$route.query.summaryProjects : this.projectIds
        this.tagList = this.$route.query.summaryTag ? typeof this.$route.query.summaryTag === 'string' ? [this.$route.query.summaryTag] : this.$route.query.summaryTag : this.tagList
        this.isBillable = this.$route.query.summaryIsBillable ? this.$route.query.summaryIsBillable : this.isBillable
        this.client_id = this.$route.query.summaryClient ? typeof this.$route.query.summaryClient === 'string' ? [this.$route.query.summaryClient] : this.$route.query.summaryClient : this.client_id
        this.memberList = this.$route.query.summaryMemberList ? typeof this.$route.query.summaryMemberList === 'string' ? [this.$route.query.summaryMemberList] : this.$route.query.summaryMemberList : this.memberList
        this.filter = {
          groupId: this.groupId,
          projectIds: this.projectIds,
          tagList: this.tagList,
          isBillable: this.isBillable,
          client_id: this.client_id,
          memberList: this.memberList,
        }
        this.firstGroupBy = this.$route.query.summaryFirst ? this.$route.query.summaryFirst : this.firstGroupBy
        this.secondGroupBy = this.$route.query.summarySecond ? this.$route.query.summarySecond : this.secondGroupBy
        this.thirdGroupBy = this.$route.query.summaryThird ? this.$route.query.summaryThird : this.thirdGroupBy
        this.activeBtn = this.$route.query.summaryActive ? this.$route.query.summaryActive : this.activeBtn
        this.setOptionValue()
        this.summaryReport()
      }
    },

    queryObject(arr) {
      const obj = {}
      if (this.firstGroupBy !== 'user_task_title' && this.secondGroupBy !== 'user_task_title' && this.thirdGroupBy !== 'user_task_title') {
        if (this.firstGroupBy === 'full_name' || this.secondGroupBy === 'full_name' || this.thirdGroupBy === 'full_name') {
          obj.member = arr.user_id
        }
        if (this.firstGroupBy === 'group_name' || this.secondGroupBy === 'group_name' || this.thirdGroupBy === 'group_name') {
          obj.group = arr.group_name
        }
        if (this.firstGroupBy === 'project_name' || this.secondGroupBy === 'project_name' || this.thirdGroupBy === 'project_name') {
          obj.project = arr.user_task_project_id
        }
        if (this.firstGroupBy === 'client_name' || this.secondGroupBy === 'client_name' || this.thirdGroupBy === 'client_name') {
          obj.client = arr.clients_id
        }
        if (this.firstGroupBy === 'tags' || this.secondGroupBy === 'tags' || this.thirdGroupBy === 'tags') {
          obj.tag = arr.tags
        }

        //  filter value set
        if (this.tagList && this.tagList.length) {
          obj.tag = this.filterValueSetInQuery(obj.tag, this.tagList, 'tag')
        }
        if (this.client_id && this.client_id.length) {
          obj.client = this.filterValueSetInQuery(obj.client, this.client_id, 'client')
        }
        if (this.projectIds && this.projectIds.length) {
          obj.project = this.filterValueSetInQuery(obj.project, this.projectIds, 'project')
        }
        if (this.memberList && this.memberList.length) {
          obj.summaryMemberList = this.filterValueSetInQuery(obj.summaryMemberList, this.memberList, 'memberList')
        }
        if (this.groupId) {
          obj.group = arr.group_name
        }

        /* currentFilter */
        obj.summaryStartDate = this.startDate
        obj.summaryEndDate = this.endDate
        obj.summaryIsBillable = this.isBillable
        obj.summaryGroup = this.groupId
        obj.summaryClient = this.client_id
        obj.summaryTag = this.tagList
        obj.summaryProjects = this.projectIds
        obj.summaryFirst = this.firstGroupBy
        obj.summarySecond = this.secondGroupBy
        obj.summaryThird = this.thirdGroupBy
        obj.summaryActive = this.activeBtn
      }

      return obj
    },

    redirectToTimeSheet(id, id2, id3) {
      let obj = {}

      if (this.secondGroupBy === 'none' || this.secondGroupBy === 'user_task_title') {
        if (id) {
          obj = this.queryObject(this.reports[id][0])
        }
      } else if (this.thirdGroupBy === 'none' || this.thirdGroupBy === 'user_task_title') {
        if (id && id2) {
          obj = this.queryObject(this.reports[id][id2][0])
        }
      } else if (id && id2 && id3) {
        obj = this.queryObject(this.reports[id][id2][id3][0])
      }
      const objLength = Object.values(obj)

      if (objLength.length) {
        this.$router.push({
          name: 'taskTimeSheetReport',
          query: obj,
        })
      }
    },
    without(value) {
      const descriptionOption = this.optionList.find(option => option.value === value)
      const descriptionValue = descriptionOption ? `(Without ${descriptionOption.label})` : null
      return descriptionValue
    },

    projectColor(value) {
      let color = ''

      if (this.firstGroupBy === 'project_name') {
        if ((this.secondGroupBy === 'none' || this.secondGroupBy === 'user_task_title')) {
          if (this.secondGroupBy === 'none') {
            color = this.reports
          && this.reports[value]
          && this.reports[value].length
          && this.reports[value][0]
          && this.reports[value][0].color
              ? `#${this.reports[value][0].color}` : color
            return color
          }

          if (this.reports
        && Object.values(this.reports[value])
        && Object.values(this.reports[value])[0]
        && Object.values(this.reports[value])[0][0]
        && Object.values(this.reports[value])[0][0].color) {
            return `#${this.reports
        && Object.values(this.reports[value])
        && Object.values(this.reports[value])[0]
        && (Object.values(this.reports[value])[0][0].color || '')}`
          }
          color = `#${this.reports
        && Object.values(this.reports[value])
        && Object.values(this.reports[value])[0]
        && (Object.values(this.reports[value])[0].color || '')}`
          return color
        }

        if (this.thirdGroupBy === 'none') {
          color = `#${this.reports
        && Object.values(this.reports[value])
        && Object.values(this.reports[value])[0]
        && Object.values(this.reports[value])[0][0]
        && (Object.values(this.reports[value])[0][0].color || '')}`
          return color
        }

        color = `#${this.reports
      && Object.values(this.reports[value])
      && Object.values(this.reports[value])[0]
      && Object.values(Object.values(this.reports[value])[0])
      && Object.values(Object.values(this.reports[value])[0])[0]
      && Object.values(Object.values(this.reports[value])[0])[0][0]
      && (Object.values(Object.values(this.reports[value])[0])[0][0].color || '')}`
        return color
      }
      if (this.secondGroupBy === 'none') {
        color = '#6e6b7b'
      }
      return color
    },

    updateDate(obj) {
      this.startDate = obj && obj.startDate ? obj.startDate : null
      this.endDate = obj && obj.endDate ? obj.endDate : null
      this.activeBtn = obj && obj.active ? obj.active : null
      this.summaryReport()
    },
    applyFilters($event) {
      this.groupId = $event.groupId
      this.projectIds = $event.projectIds
      this.tagList = $event.tagList
      this.isBillable = $event.isBillable
      this.client_id = $event.client_id
      this.memberList = $event.memberList
      if ($event.clear) {
        this.startDate = moment().startOf('week').add(1, 'days').format('YYYY-MM-DD')
        this.endDate = moment().format('YYYY-MM-DD')
      }
      this.summaryReport()
    },
    setOptionValue() {
      /*  condition */

      if (
        this.secondGroupBy === this.thirdGroupBy
        || !this.secondGroupBy
        || this.secondGroupBy === 'none'
      ) {
        this.thirdGroupBy = 'none'
      }
      if (
        this.secondGroupBy === 'user_task_title' && this.thirdGroupBy === 'group_name'

      ) {
        this.thirdGroupBy = 'none'
      }
      if (this.firstGroupBy === this.secondGroupBy) {
        this.secondGroupBy = 'user_task_title'
        this.thirdGroupBy = 'none'
      }

      /** value set */
      this.firstGroupByList = this.optionList.filter(
        (item, index) => index !== 0 && index !== this.optionList.length - 1,
      )

      // Filter out the firstGroupBy value
      this.secondGroupByList = this.optionList.filter(item => {
        if (this.firstGroupBy === 'full_name') {
          return item.value !== this.firstGroupBy && item.value !== 'group_name'
        }
        if (this.firstGroupBy === 'project_name') {
          return item.value !== this.firstGroupBy && item.value !== 'client_name'
        }
        return (
          item.value !== this.firstGroupBy
          && (item.value !== 'client_name'
            || this.firstGroupBy === 'group_name'
            || this.firstGroupBy === 'tags'
            || this.firstGroupBy === 'full_name')
        )
      })

      // Check second groupBy key exits after secondGroupByList update
      // if (this.firstGroupBy === this.thirdGroupBy) {
      if (!this.secondGroupByList.find(item => item.value === this.secondGroupBy)) {
        this.secondGroupBy = 'none'
      }
      // }
      if (this.firstGroupBy === this.thirdGroupBy) {
        this.thirdGroupBy = 'none'
      }
      // Filter out both firstGroupBy and secondGroupBy values
      let isShowClient
      this.thirdGroupByList = this.optionList.filter(
        item => {
          const isNotFirstOrSecondGroupBy = item.value !== this.firstGroupBy && item.value !== this.secondGroupBy && item.value !== 'client_name'

          isShowClient = (this.firstGroupBy === 'full_name' && this.secondGroupBy === 'tags')
            || (this.firstGroupBy === 'group_name' && this.secondGroupBy === 'full_name')
            || (this.firstGroupBy === 'group_name' && this.secondGroupBy === 'tags')
            || (this.firstGroupBy === 'tags' && this.secondGroupBy === 'full_name')
            || (this.firstGroupBy === 'tags' && this.secondGroupBy === 'group_name')

          // Additional condition for 'project' and 'user'
          const isNotProjectAndClient = !(this.firstGroupBy === 'project_name' && this.secondGroupBy === 'full_name' && (item.value === 'project_name' || item.value === 'client_name' || item.value === 'group_name'))

          const clientProject = !(this.firstGroupBy === 'client_name' && this.secondGroupBy === 'project_name' && (item.value === 'user_task_title'))

          const clientUser = !(this.firstGroupBy === 'client_name' && this.secondGroupBy === 'full_name' && (item.value === 'group_name'))

          const userProject = !(this.firstGroupBy === 'full_name' && (this.secondGroupBy === 'project_name' || this.secondGroupBy === 'client_name') && (item.value === 'group_name'))

          const tagUser = !((this.firstGroupBy === 'tags' || this.firstGroupBy === 'full_name') && (this.secondGroupBy === 'full_name' || this.secondGroupBy === 'tags') && (item.value === 'group_name'))

          return isNotFirstOrSecondGroupBy && isNotProjectAndClient && clientProject && clientUser && tagUser && userProject
        },
      )

      if (isShowClient) {
        this.thirdGroupByList.push({
          text: 'Client',
          value: 'client_name',
        })
      }

      if (!this.thirdGroupByList.find(item => item.value === this.thirdGroupBy)) {
        this.thirdGroupBy = 'none'
      }
      if (this.objectKeys && this.objectKeys.length) { this.summaryReport() }
    },
    /**
     * Get report data
     */
    async summaryReport() {
      this.loader = true

      if (Object.values(this.$route.query).length) {
        this.routeQueryUpdate({})
      }
      const input = {
        start_date: this.startDate,
        end_date: this.endDate,
        member: this.searchUser ? [this.searchUser] : [],
        group_id: this.groupId,
        project: this.projectIds,
        tags: this.tagList,
        client_id: this.client_id,
        billable: this.isBillable ? this.isBillable === 'yes' : null,
        user_ids: this.memberList,
        first_groupBy:
          this.firstGroupBy && this.firstGroupBy !== 'none'
            ? this.firstGroupBy
            : null,
        second_groupBy:
          this.secondGroupBy && this.secondGroupBy !== 'none'
            ? this.secondGroupBy
            : null,
        third_groupBy:
          this.thirdGroupBy && this.thirdGroupBy !== 'none'
            ? this.thirdGroupBy
            : null,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/reports/task-summary-report',
        input,
        false,
      )
      if (response && response.status === 200) {
        const { data } = response
        this.totalTime = data.totalTime
        this.reports = data.user_tasks
        this.objectKeys = Object.keys(this.reports)
      }
      this.loader = false
    },

    getTotalTime(arr) {
      let time = 0

      if (arr && arr.length) {
        arr.forEach(element => {
          time += element.total_time
        })
      }
      return this.secondsToTime(time * 60)
    },

    getProjectTotalTime(obj) {
      let time = 0
      if (this.thirdGroupBy && this.thirdGroupBy !== 'none') {
        const keys = Object.keys(obj)
        keys.forEach(e => {
          if (obj[e] && obj[e].length) {
            obj[e].forEach(el => {
              time += el.total_time
            })
          }
        })
      } else if (obj && obj.length) {
        if (obj && obj.length) {
          obj.forEach(e => {
            time += e.total_time
          })
        }
      }

      return this.secondsToTime(time * 60)
    },
    objectKeysLength(index) {
      const keys = Object.keys(index)
      return keys.length
    },

    userProjectDescriptionTotalTime(arr) {
      let time = 0
      const projectKeys = Object.keys(arr)
      if (this.thirdGroupBy && this.thirdGroupBy !== 'none') {
        projectKeys.forEach(e => {
          Object.keys(arr[e]).forEach(el => {
            if (arr[e][el] && arr[e][el].length) {
              arr[e][el].forEach(element => {
                time += element.total_time
              })
            }
          })
        })
      } else {
        projectKeys.forEach(e => {
          if (arr[e] && arr[e].length) {
            arr[e].forEach(el => {
              time += el.total_time
            })
          }
        })
      }
      return this.secondsToTime(time * 60)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/component-css/taskSummaryReport.scss";
.badge-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  color: #02587f !important;
  background-color: #E1F5FE;
}

</style>
